import React from "react";
import styles from "./Well.scss";
import classNames from "classnames";

export const Well = ({ children, margin, variant }) => {
    return (
        <div
            className={classNames(styles.base, {
                [styles.marginSmall]: margin === "small",
                [styles.marginRegular]: margin === "regular",
                [styles.variantDefault]: !variant || variant === "default",
                [styles.variantAttention]: variant === "attention"
            })}
        >
            <div className={styles.content}>{children}</div>
        </div>
    );
};
