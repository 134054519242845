import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { ContentWrapper } from "../../layout/ContentWrapper/ContentWrapper";
import { PageHeader } from "../PageHeader";
import styles from "./styles.scss";
import { PageDrawer } from "../PageDrawer";

export const Page = ({ children }) => {
    const topRef = useRef(null);
    const [pinnedHeader, setPinnedHeader] = useState(false);
    let mainBox = [];
    let pageHeader = null;
    let pageDrawer = null;

    useEffect(() => {
        const handleTogglePinned = (e) => {
            const ratio = e?.[0].intersectionRatio;
            setPinnedHeader(ratio < 1);
        };

        const topElement = topRef.current;
        if (!topElement) return;
        const observer = new IntersectionObserver(handleTogglePinned, {
            rootMargin: "-64px 0px 0px 0px",
            threshold: 1
        });
        observer.observe(topElement);
        return () => {
            if (topElement) observer.unobserve(topElement);
        };
    }, [topRef]);

    React.Children.map(children, (child) => {
        if (child) {
            if (React.isValidElement(child) && child.type === PageHeader) {
                pageHeader = React.cloneElement(child, { pinned: pinnedHeader });
            } else if (React.isValidElement(child) && child.type === PageDrawer) {
                pageDrawer = child;
            } else {
                mainBox.push(child);
            }
        }
    });

    return (
        <div className={styles.base}>
            {pageDrawer && <div className={styles.drawerPane}>{pageDrawer}</div>}
            <div className={styles.mainPane}>
                <div ref={topRef} />
                {pageHeader && (
                    <div className={classNames(styles.headerBlock, { [styles.pinned]: pinnedHeader })}>
                        {pageHeader}
                    </div>
                )}
                {mainBox.length > 0 ? (
                    <div className={styles.contentBlock}>
                        <ContentWrapper>{mainBox}</ContentWrapper>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
