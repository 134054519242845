import React, { useEffect, useRef, useState } from "react";
import styles from "./Breadcrumbs.scss";
import classNames from "classnames";

export const Breadcrumbs = ({ children }) => {
    const [compact, setCompact] = useState(false);
    const baseRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        const baseElement = baseRef.current;
        const containerElement = containerRef.current;

        const handleResize = () => {
            setCompact(containerElement.scrollWidth > baseElement.clientWidth);
        };

        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(baseElement);
        resizeObserver.observe(containerElement);

        // Initial check
        handleResize();
        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <div
            ref={baseRef}
            className={classNames(styles.base, {
                [styles.compact]: compact
            })}
        >
            <div ref={containerRef} className={styles.container}>
                {children}
            </div>
        </div>
    );
};
