import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { getFlatGroupListFromStructure } from "../../../utils/inventoryStructureUtils";
import { CustomSelect, OptionItem } from "../../form/CustomSelect";
import { Text } from "../../typography/Text";

const msgKey = "inventoryGroupSelect";

export const InventoryGroupSelect = ({ value, onChange, structure, noRoot }) => {
    const groups = useMemo(() => getFlatGroupListFromStructure(structure), [structure]);
    return (
        <CustomSelect
            value={value ? parseInt(value, 10) : null}
            placeholder={
                noRoot ? (
                    <FormattedMessage id={`${msgKey}.ungrouped`} />
                ) : (
                    <FormattedMessage id={`${msgKey}.rootLevel`} />
                )
            }
            onChange={onChange}
            isClearable
            isSearchable
            filterOption={(option, searchTerm) => {
                if (!searchTerm || !searchTerm.length) return true;
                const group = groups.find((g) => g.id === option.value);
                return (
                    group.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (group.code && group.code.toLowerCase().includes(searchTerm.toLowerCase()))
                );
            }}
            noOptionsMessage={() => <FormattedMessage id={`${msgKey}.noGroups`} />}
        >
            {groups.map((group) => (
                <OptionItem
                    key={group.id}
                    value={group.id}
                    symbol={group.code ? <Text color="base40">{group.code}</Text> : null}
                    label={group.title}
                />
            ))}
        </CustomSelect>
    );
};
