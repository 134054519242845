import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../button/Button";
import { Popup, PopupButtons, PopupContent, PopupTitle } from ".";
import { Text } from "../../typography/Text";

const msgKey = "popupAlert";

export const PopupAlert = ({ title, message, type, onOk }) => {
    return (
        <Popup onClose={onOk} type={type}>
            <PopupTitle>{title}</PopupTitle>
            <PopupContent>
                <Text size="medium">{message}</Text>
            </PopupContent>
            <PopupButtons>
                <Button onClick={onOk}>
                    <FormattedMessage id={`${msgKey}.ok`} />
                </Button>
            </PopupButtons>
        </Popup>
    );
};
