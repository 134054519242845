import React from "react";
import { useIntl } from "react-intl";
import wordUtils from "../../../utils/wordUtils";

export const HighlightText = React.memo(
    ({ term, word, text }) => {
        const intl = useIntl();

        const renderText = (term, text) => {
            if (typeof text !== "string") return text;
            const regex = new RegExp(term, "gi");
            const html = text.replace(regex, (match) => {
                return `<mark>${match}</mark>`;
            });
            return <div dangerouslySetInnerHTML={{ __html: html }} />;
        };

        if (!text && !word) return null;

        if (term && text) {
            return renderText(term, text);
        } else if (word) {
            const wordValue = wordUtils.getValue(intl.locale, word);
            return term ? renderText(term, wordValue) : wordValue;
        } else {
            return text;
        }
    },
    (prevProps, nextProps) => {
        return (
            prevProps.term === nextProps.term && prevProps.text === nextProps.text && prevProps.word === nextProps.word
        );
    }
);
