import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FormattedMessage } from "react-intl";
import { AuthContext } from "../../../contexts/Auth";
import { Button } from "../../button/Button";
import Curtain from "../../misc/Curtain/Curtain";
import { ErrorScreen } from "../../screens/ErrorScreen";

/**
 * Show login form if not logged in on this route or
 * show restricted message if user not authorized.
 */
export const AuthenticatedRoute = ({ element, roles }) => {
    const navigate = useNavigate();
    const { busy, me } = useContext(AuthContext);
    const [authorized, setAuthorized] = useState(null);

    useEffect(() => {
        // Check if busy
        if (busy) {
            return;
        }

        // Check authorization
        let authCheck = true;
        if (roles && me?.user.roles?.length) {
            const rolesList = roles.split(",");
            for (let i = 0; i < rolesList.length; i++) {
                if (me?.user.roles.indexOf(rolesList[i]) < 0) {
                    authCheck = false;
                }
            }
        } else if (me?.user?.roles) {
            authCheck = true;
        } else {
            authCheck = false;
        }
        setAuthorized(authCheck);

        // Not logged in
        if (!me) {
            console.log("[VP] Redirecting to login");
            navigate("/login", {
                replace: true,
                state: {
                    from: navigate.location
                }
            });
        }
    }, [busy, me, roles, navigate]);

    // Check if authorized
    if ((busy && !me) || authorized === null) {
        return <Curtain />;
    } else if (!authorized || !me) {
        return (
            <ErrorScreen
                title={<FormattedMessage id="routeAuth.title" />}
                message={<FormattedMessage id="routeAuth.message" />}
            >
                <Button link="/me" icon="arrow-left" iconColor="primary">
                    <FormattedMessage id="routeAuth.back" />
                </Button>
            </ErrorScreen>
        );
    } else {
        return element;
    }
};
