import classNames from "classnames";
import React from "react";
import { useLocation, useResolvedPath } from "react-router-dom";
import { Clickable } from "../../button/Clickable";
import { Icon } from "../../graphic/Icon";
import style from "./style.scss";

export const PageButton = ({ icon, label, count, active, onClick, link, exact = false }) => {
    const location = useLocation();
    const resolvedLink = useResolvedPath(link);

    return (
        <Clickable
            className={classNames(style.button, {
                [style.active]:
                    (!!link &&
                        (exact
                            ? resolvedLink.pathname === location.pathname
                            : location.pathname.startsWith(resolvedLink.pathname))) ||
                    active
            })}
            link={link}
            replace={false}
            onClick={!active ? onClick : null}
        >
            <div className={style.icon}>
                <Icon name={icon} />
            </div>
            <div className={style.label}>{label}</div>

            {count ? (
                <div className={style.count}>
                    <div className={style.number}>{count}</div>
                </div>
            ) : null}
        </Clickable>
    );
};
