import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../button/Button";
import Col from "../../grid/Col";
import Row from "../../grid/Row";
import { Text } from "../../typography/Text";
import { Popup, PopupContent, PopupFooter, PopupTitle } from ".";

export const PopupYesNo = ({ title, children, busy, onYes, onNo }) => {
    return (
        <Popup onClose={onNo}>
            <PopupTitle>{title}</PopupTitle>
            <PopupContent>
                <Text size="medium">{children}</Text>
            </PopupContent>
            <PopupFooter>
                <Row gutter={["small", "small"]}>
                    <Col>
                        <Button name="yes" icon="check" iconColor="success" onClick={onYes} busy={busy}>
                            <FormattedMessage id="popupYesNo.yes" />
                        </Button>
                    </Col>
                    <Col>
                        <Button name="no" icon="close" iconColor="error" onClick={onNo} disabled={busy}>
                            <FormattedMessage id="popupYesNo.no" />
                        </Button>
                    </Col>
                </Row>
            </PopupFooter>
        </Popup>
    );
};
