import React from "react";
import styles from "./DividerText.scss";

export const DividerText = ({ children }) => {
    return (
        <div className={styles.base}>
            <div className={styles.divider} />
            <div className={styles.text}>{children}</div>
            <div className={styles.divider} />
        </div>
    );
};
