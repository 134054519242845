import React from "react";
import classNames from "classnames";
import styles from "./Text.scss";

export const Text = ({
    block,
    truncate,

    weight,
    size,
    color,

    lineThrough,
    lowercase,
    uppercase,
    italic,
    element,
    spacing,
    title,
    lineHeight,
    align,

    children,
    className
}) => {
    return (
        <span
            className={classNames(
                styles.base,
                {
                    [styles.block]: block,
                    [styles.truncate]: truncate,

                    [styles.weightRegular]: weight === "regular",
                    [styles.weightSemiBold]: weight === "semiBold",
                    [styles.weightBold]: weight === "bold",

                    [styles.small]: size === "small",
                    [styles.regular]: size === "regular",
                    [styles.medium]: size === "medium",
                    [styles.large]: size === "large",
                    [styles.xlarge]: size === "xlarge",

                    [styles.base80]: color === "base80",
                    [styles.base60]: color === "base60",
                    [styles.base40]: color === "base40",
                    [styles.base20]: color === "base20",

                    [styles.primary]: color === "primary",
                    [styles.success]: color === "success",
                    [styles.warning]: color === "warning",
                    [styles.error]: color === "error",
                    [styles.info]: color === "info",

                    [styles.lineHeightSmall]: lineHeight === "small",

                    [styles.elementSmall]: element === "small",
                    [styles.elementNormal]: element === "normal",

                    [styles.spacingSmall]: spacing === "small",

                    [styles.lineThrough]: lineThrough,
                    [styles.lowercase]: lowercase,
                    [styles.uppercase]: uppercase,
                    [styles.italic]: italic,

                    [styles.alignLeft]: align === "left",
                    [styles.alignCenter]: align === "center"
                },
                className
            )}
            style={styles}
            title={title}
        >
            {children}
        </span>
    );
};
