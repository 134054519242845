import React from "react";
import styles from "./Controls.scss";
import Row from "../../../grid/Row";
import Col from "../../../grid/Col";
import { Button } from "../../../button/Button";
import { ButtonGroup } from "../../../button/ButtonGroup";
import { FormattedMessage } from "react-intl";

const msgKey = "treeMenu";

export const Controls = ({ tree, editable, editMode, setEditMode }) => {
    return (
        <div className={styles.controls}>
            <Row gutter={["tiny", "tiny"]}>
                <Col>
                    <ButtonGroup>
                        <Button square icon="expand" size="small" onClick={() => tree?.openAll()} />
                        <Button square icon="collapse" size="small" onClick={() => tree?.closeAll()} />
                    </ButtonGroup>
                </Col>
                {editable && (
                    <Col>
                        {editMode ? (
                            <Button iconColor="error" icon="close" size="small" onClick={() => setEditMode(false)}>
                                <FormattedMessage id={`${msgKey}.stopEditing`} />
                            </Button>
                        ) : (
                            <Button iconColor="primary" icon="edit" size="small" onClick={() => setEditMode(true)}>
                                <FormattedMessage id={`${msgKey}.edit`} />
                            </Button>
                        )}
                    </Col>
                )}
            </Row>
        </div>
    );
};
