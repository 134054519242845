import classNames from "classnames";
import React from "react";
import { Clickable } from "../../button/Clickable";
import styles from "./styles.scss";

export const Radio = ({ value, onSelect, groupValue, children }) => {
    return (
        <Clickable
            block
            className={classNames(styles.radio, {
                [styles.selected]: groupValue === value
            })}
            onClick={() => onSelect(value)}
        >
            <div className={styles.symbol}>
                <div className={styles.dot}></div>
            </div>
            {children ? <div className={styles.label}>{children}</div> : null}
        </Clickable>
    );
};
