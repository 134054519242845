import React from "react";
import styles from "./style.scss";

import { Icon } from "../../graphic/Icon";
import classNames from "classnames";

export const BoxTitle = ({ id, icon, onToggleCollapse, badge, action, children, underTitle }) => {
    return (
        <div
            id={id}
            className={classNames(styles.title, {
                [styles.underTitle]: underTitle
            })}
        >
            {/* Icon */}
            {icon ? (
                <div className={styles.icon}>
                    <Icon name={icon} />
                </div>
            ) : null}

            {/* Label */}
            <div className={styles.label}>{children}</div>

            {/* Badge */}
            {badge ? <div className={styles.badge}>{badge}</div> : null}

            {/* Actions */}
            <ul className={styles.actions}>
                {action ? <li>{action}</li> : null}

                {/* Collapse toggle */}
                {onToggleCollapse ? (
                    <li className={styles.collapseButton} onClick={onToggleCollapse}>
                        <Icon name="caret-down" className={styles.caret} />
                    </li>
                ) : null}
            </ul>
        </div>
    );
};
