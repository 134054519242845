import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";

export const PopupButtons = ({ children, filled }) => {
    let mainButton = null;
    let extraButton = null;

    React.Children.map(children, (child) => {
        if (child) {
            const clonedChild = React.cloneElement(child, {
                className: classNames(child.props.className, styles.buttonElement)
            });

            if (!mainButton) {
                mainButton = clonedChild;
            } else {
                extraButton = clonedChild;
            }
        }
    });

    return (
        <div
            className={classNames(styles.buttons, {
                [styles.twoButtons]: extraButton !== null,
                [styles.filled]: filled
            })}
        >
            {mainButton ? <div className={styles.mainButton}>{mainButton}</div> : null}
            {extraButton ? <div className={styles.extraButton}>{extraButton}</div> : null}
        </div>
    );
};
