import React from "react";
import classNames from "classnames";
import style from "./style.scss";
import { Spinner } from "../../loaders/Spinner";

export const RadioList = ({ value, onChange, error, busy, heading, children }) => {
    return (
        <div
            className={classNames(style.base, {
                [style.error]: error
            })}
        >
            {/* Loader */}
            {busy ? (
                <div className={style.loader}>
                    <Spinner primary className={style.spinner} />
                </div>
            ) : null}

            {/* Heading */}
            {heading ? (
                <div className={style.heading}>
                    <div className={style.label}>{heading}</div>
                </div>
            ) : null}

            {React.Children.map(children, (child) =>
                React.cloneElement(child, {
                    ...child.props,
                    groupValue: value,
                    onChange
                })
            )}
        </div>
    );
};
