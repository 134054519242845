import React from "react";
import styles from "./styles.scss";

export const RadioGroup = ({ value, onChange, children }) => {
    return (
        <div className={styles.group}>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    groupValue: value,
                    onSelect: onChange
                });
            })}
        </div>
    );
};
