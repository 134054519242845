import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";

export const PopupTitle = ({ subtitle, size, align, children }) => {
    return (
        <div
            className={classNames(styles.title, {
                [styles.sizeMedium]: size === "medium",
                [styles.alignCenter]: align === "center"
            })}
        >
            {children}
            {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : null}
        </div>
    );
};
