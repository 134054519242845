import classNames from "classnames";
import React, { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../../App";
import style from "./style.scss";
import { ScrollRestoration } from "react-router-dom";

export const MainPane = ({ children, noBar }) => {
    const paneRef = useRef(null);
    const { hasSidebar, setScrollContainerElement } = useContext(AppContext);

    useEffect(() => {
        setScrollContainerElement(paneRef.current);
    }, [setScrollContainerElement]);

    return (
        <div
            id="main-pane"
            ref={paneRef}
            className={classNames(style.base, {
                [style.noBar]: noBar,
                [style.hasSidebar]: hasSidebar
            })}
        >
            {children}
            <ScrollRestoration />
        </div>
    );
};
