import React, { useEffect, useState } from "react";
import styles from "./PageDrawer.scss";
import classNames from "classnames";
import { Icon } from "../../graphic/Icon";

export const PageDrawer = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const [initialSlideOut, setInitialSlideOut] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setInitialSlideOut(false);
        }, 1000); // Duration of the slide-out animation
        return () => clearTimeout(timer);
    }, []);

    return (
        <div
            className={classNames(styles.base, {
                [styles.visible]: visible,
                [styles.slideOut]: initialSlideOut
            })}
            onMouseLeave={() => setVisible(false)}
        >
            <div className={styles.content}>{children}</div>
            <div className={styles.handle} onMouseEnter={() => setVisible(true)} onClick={() => setVisible(true)}>
                <div className={styles.flip}>
                    <svg
                        className={styles.dots}
                        width="4"
                        height="44"
                        viewBox="0 0 4 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="2" cy="26" r="2" fill="#D3DDEB" />
                        <circle cx="2" cy="34" r="2" fill="#D3DDEB" />
                        <circle cx="2" cy="42" r="2" fill="#D3DDEB" />
                        <circle cx="2" cy="2" r="2" fill="#D3DDEB" />
                        <circle cx="2" cy="10" r="2" fill="#D3DDEB" />
                        <circle cx="2" cy="18" r="2" fill="#D3DDEB" />
                    </svg>
                    <Icon name="chevron-right" color="primary" />
                    <svg
                        className={styles.dots}
                        width="4"
                        height="44"
                        viewBox="0 0 4 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="2" cy="26" r="2" fill="#D3DDEB" />
                        <circle cx="2" cy="34" r="2" fill="#D3DDEB" />
                        <circle cx="2" cy="42" r="2" fill="#D3DDEB" />
                        <circle cx="2" cy="2" r="2" fill="#D3DDEB" />
                        <circle cx="2" cy="10" r="2" fill="#D3DDEB" />
                        <circle cx="2" cy="18" r="2" fill="#D3DDEB" />
                    </svg>
                </div>
            </div>
            <div className={styles.overlay} onClick={() => setVisible(false)} />
        </div>
    );
};
