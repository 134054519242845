import React from "react";
import classNames from "classnames";
import style from "./style.scss";
import intlUtils from "../../../utils/intlUtils";
import Notification from "rc-notification";
import { Icon } from "../../graphic/Icon";

let defaultDuration = 3;
let key = 1;

let messageInstance;
Notification.newInstance(
    {
        transitionName: "fade",
        style: {
            top: "auto",
            bottom: "10px"
        }
    },
    (n) => (messageInstance = n)
);

function notice(content, values, duration, type, onClose) {
    if (!messageInstance) {
        return null;
    }

    let icon = {
        info: <Icon className={style.icon} name={"info"} />,
        success: <Icon className={style.icon} name={"check"} />,
        error: <Icon className={style.icon} name={"error-circle"} />,
        warning: <Icon className={style.icon} name={"warning-triangle"} />,
        loading: <Icon className={style.icon} name={"refresh"} rotate />
    }[type];

    const classes = classNames(style.base, style[type]);

    const intl = intlUtils.getIntl();
    const message = intl.formatMessage({ id: content }, values);

    messageInstance.notice({
        key,
        duration: duration || defaultDuration,
        style: {},
        content: (
            <div className={classes}>
                {icon}
                <span className={style.content}>{message}</span>
            </div>
        ),
        onClose
    });

    return (function () {
        let target = key++;
        return function () {
            messageInstance.removeNotice(target);
        };
    })();
}

export default {
    info(content, values, duration, onClose) {
        return notice(content, values, duration, "info", onClose);
    },

    success(content, values, duration, onClose) {
        return notice(content, values, duration, "success", onClose);
    },

    error(content, values, duration = 10, onClose) {
        return notice(content, values, duration, "error", onClose);
    },

    warning(content, values, duration, onClose) {
        return notice(content, values, duration, "warning", onClose);
    },

    loading(content, values, duration, onClose) {
        return notice(content, values, duration, "loading", onClose);
    },

    destroy() {
        if (messageInstance) {
            messageInstance.destroy();
            messageInstance = null;
        }
    }
};
