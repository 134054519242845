import React from "react";
import { CenterBox } from "../../layout/CenterBox";
import { CenterBoxBottom } from "../../layout/CenterBoxBottom";
import { CenterBoxContent } from "../../layout/CenterBoxContent";
import { CenterBoxTitle } from "../../layout/CenterBoxTitle";
import { LayoutFull } from "../../layout/LayoutFull";
import { Text } from "../../typography/Text";

export const ErrorScreen = ({ title, message, children }) => {
    return (
        <LayoutFull>
            <CenterBox>
                <CenterBoxTitle>{title}</CenterBoxTitle>
                <CenterBoxContent>
                    <Text block size="medium" color="base80" align="center">
                        {message}
                    </Text>
                </CenterBoxContent>
                <CenterBoxBottom>{children}</CenterBoxBottom>
            </CenterBox>
        </LayoutFull>
    );
};
