import React from "react";
import styles from "./ContentWrapper.scss";
import classNames from "classnames";

export const ContentWrapper = ({ align, children }) => {
    return (
        <div
            className={classNames(styles.base, {
                [styles.alignLeft]: align === "left"
            })}
        >
            <div className={styles.wrapper}>{children}</div>
        </div>
    );
};
