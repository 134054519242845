import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import style from "./style";

export const Clickable = React.forwardRef(
    (
        {
            link,
            replace = false,
            href,
            className,
            activeClassName,
            target,
            block,
            children,
            onClick,
            onMouseDown,
            title,
            cy
        },
        ref
    ) => {
        const handleClick = (event) => {
            if (onClick) {
                onClick(event);
            }
        };

        if (link) {
            return (
                <NavLink
                    end
                    to={link}
                    replace={replace}
                    onClick={handleClick}
                    className={({ isActive }) =>
                        classNames(
                            style.base,
                            {
                                [activeClassName]: isActive && !!activeClassName
                            },
                            className
                        )
                    }
                    title={title}
                    data-cy={cy}
                >
                    {children}
                </NavLink>
            );
        } else if (href) {
            return (
                <a href={href} target={target} className={classNames(style.base, className)} title={title} data-cy={cy}>
                    {children}
                </a>
            );
        } else if (block) {
            return (
                <div
                    ref={ref}
                    onClick={handleClick}
                    className={classNames(style.base, className)}
                    title={title}
                    data-cy={cy}
                >
                    {children}
                </div>
            );
        } else {
            return (
                <span
                    ref={ref}
                    onClick={handleClick}
                    onMouseDown={onMouseDown}
                    className={classNames(style.base, className)}
                    title={title}
                    data-cy={cy}
                >
                    {children}
                </span>
            );
        }
    }
);
