import React, { useContext, useEffect, useState } from "react";

import { VesselContext } from "../../../routes/vessel/VesselRoot";
import { BuyVesselSubscriptionMessage } from "../../messages/BuyVesselSubscriptionMessage";
import NoAccessMessage from "../../messages/NoAccessMessage";
import TrialExpiredMessage from "../../messages/TrialExpiredMessage";
import { Page } from "../../page/Page";

export const RestrictedVesselRoute = ({ restrictTo, hasValidSubscription, element }) => {
    const { vessel, vesselAccount, vesselRole } = useContext(VesselContext);
    const [noAccess, setNoAccess] = useState(null);
    const [activeAccount, setActiveAccount] = useState(null);

    useEffect(() => {
        setNoAccess(restrictTo && restrictTo.indexOf(vesselRole) < 0);
    }, [restrictTo, vesselRole]);

    useEffect(() => {
        setActiveAccount(vesselAccount && vesselAccount.isActive);
    }, [vesselAccount]);

    if (noAccess === null || activeAccount === null) {
        return null;
    } else if ((!hasValidSubscription || activeAccount) && !noAccess) {
        return element;
    } else {
        return (
            <Page>
                {noAccess ? (
                    <NoAccessMessage />
                ) : vesselAccount.status === "TRIAL" ? (
                    <TrialExpiredMessage vessel={vessel} />
                ) : (
                    <BuyVesselSubscriptionMessage vessel={vessel} vesselRole={vesselRole} />
                )}
            </Page>
        );
    }
};
