import React, { useEffect, useState } from "react";
import styles from "./PhoneInput.scss";

import { FormattedMessage } from "react-intl";
import { getCountryDialCode } from "../../../utils/countryUtil";
import { Button } from "../../button/Button";
import { Flag } from "../../graphic/Flag";
import { Popup, PopupButtons, PopupContent } from "../../notify/Popup";
import { CountrySelect } from "../../selects/CountrySelect";
import Paragraph from "../../typography/Paragraph";
import { Text } from "../../typography/Text";
import Input from "../Input";

export const PhoneInput = ({ value, onChange, placeholder, disabled }) => {
    const { number, countryCode } = value || {};
    const [dialCode, setDialCode] = useState(null);
    const [promptCountry, setPromptCountry] = useState(false);

    useEffect(() => {
        let dialCode = null;
        if (countryCode) {
            dialCode = getCountryDialCode(countryCode);
        }
        setDialCode(dialCode || null);
    }, [countryCode]);

    return (
        <div className={styles.base}>
            <Input
                type="tel"
                maxLength={20}
                value={number}
                placeholder={placeholder}
                disabled={disabled}
                prepend={
                    <div className={styles.prepend}>
                        <Flag code={countryCode} className={styles.flag} />
                        {dialCode ? (
                            <Text color="base60" className={styles.dialCode}>
                                +{dialCode}
                            </Text>
                        ) : null}
                    </div>
                }
                onPrependClick={() => setPromptCountry(true)}
                onChange={(e) => onChange({ number: e.target.value?.replace(/\D/g, ""), countryCode })}
            />

            {promptCountry ? (
                <PromptCountrySelect
                    value={countryCode}
                    onSelect={(val) => onChange({ number, countryCode: val }) & setPromptCountry(false)}
                    onClose={() => setPromptCountry(false)}
                />
            ) : null}
        </div>
    );
};

const PromptCountrySelect = ({ value, onSelect, onClose }) => {
    const [countryCode, setCountryCode] = useState(value);
    return (
        <Popup onClose={onClose}>
            <PopupContent>
                <Paragraph>
                    <Text size="medium" weight="semiBold" color="base80">
                        <FormattedMessage id="phoneInput.chooseCountryDialCode" />:
                    </Text>
                </Paragraph>

                <CountrySelect value={countryCode} onChange={setCountryCode} />
            </PopupContent>
            <PopupButtons>
                <Button icon="check" color="primary" onClick={() => onSelect(countryCode)}>
                    <FormattedMessage id="phoneInput.choose" />
                </Button>
                <Button onClick={onClose}>
                    <FormattedMessage id="phoneInput.cancel" />
                </Button>
            </PopupButtons>
        </Popup>
    );
};
