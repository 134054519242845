import moment from "moment";
import React, { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AuthContext } from "../../../../contexts/Auth";
import { Alert } from "../../../alert/Alert";
import { Button } from "../../../button/Button";
import { Checkbox } from "../../../form/Checkbox";
import { SignaturePad } from "../../../form/SignaturePad";
import { Col } from "../../../grid/Col/Col";
import { Row } from "../../../grid/Row/Row";
import { Popup, PopupContent, PopupFooter, PopupTitle } from "../../../notify/Popup";
import { PopupLoader } from "../../../notify/PopupLoader";
import { Text } from "../../../typography/Text";

const msgKey = "timeLogReview";

export const TimeLogReview = ({ crew, date, summary, onClose, onSubmit }) => {
    const intl = useIntl();
    const { me } = useContext(AuthContext);
    const [signature, setSignature] = useState(null);
    const [confirmed, setConfirmed] = useState(false);
    const [busySubmitting, setBusySubmitting] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async () => {
        setBusySubmitting(true);
        await onSubmit({ crewId: crew.id, timePeriod: moment(date).format(), signature }).catch((err) => {
            switch (err?.target) {
                case "reviewer_signature":
                    return setError(`${msgKey}.errorReviewerSignature`);
                case "crew_signature":
                    return setError(`${msgKey}.errorCrewSignature`);
                default:
                    return setError(`${msgKey}.errorSubmitting`);
            }
        });
        setBusySubmitting(false);
    };

    return busySubmitting ? (
        <PopupLoader label={<FormattedMessage id={`${msgKey}.creatingReview`} />} />
    ) : (
        <Popup onClose={onClose}>
            <PopupTitle
                subtitle={
                    <Text>
                        {crew.user.fullName} / {moment(date).format("MMMM YYYY")}
                    </Text>
                }
            >
                <FormattedMessage id={`${msgKey}.title`} />
            </PopupTitle>
            <PopupContent>
                <Row>
                    <Col span={24}>
                        <Text size="medium">
                            <FormattedMessage
                                id={`${msgKey}.summary`}
                                values={{
                                    name: <strong>{crew.user.fullName}</strong>,
                                    date: <strong>{moment(date).format("MMMM YYYY")}</strong>,
                                    hours: <strong>{intl.formatNumber(summary?.hourSum)}</strong>
                                }}
                            />
                        </Text>
                    </Col>
                    <Col span={24}>
                        <Text color="base60" italic>
                            <FormattedMessage id={`${msgKey}.signee`} />
                        </Text>
                    </Col>
                    <Col span={24}>
                        {me?.user.signature ? (
                            <Row gutter={["tiny", "tiny"]}>
                                <Col span={24}>
                                    <img
                                        alt={me?.user.fullName}
                                        src={me?.user.signature?.url}
                                        style={{ width: "100%", border: "1px solid #ddd" }}
                                    />
                                </Col>
                                <Col span={24} align={["right", "top"]}>
                                    <Button size="small" color="silent" link={`/me/profile`}>
                                        <FormattedMessage id={`${msgKey}.editSignature`} />
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            <SignaturePad onChange={setSignature} />
                        )}
                    </Col>
                    <Col span={24}>
                        <Alert type="info">
                            <Checkbox noMargin checked={confirmed} onToggle={() => setConfirmed(!confirmed)}>
                                <FormattedMessage
                                    id={`${msgKey}.confirmation`}
                                    values={{ signee: <strong>{me?.user.fullName}</strong> }}
                                />
                            </Checkbox>
                        </Alert>
                    </Col>
                    {error ? (
                        <Col span={24}>
                            <Alert type="error">
                                <FormattedMessage id={error} />
                            </Alert>
                        </Col>
                    ) : null}
                </Row>
            </PopupContent>
            <PopupFooter>
                <Button
                    type="submit"
                    icon="check"
                    iconColor="success"
                    disabled={!confirmed || (!signature && !me?.user.signature)}
                    onClick={handleSubmit}
                    busy={busySubmitting}
                >
                    <FormattedMessage id={`${msgKey}.registerReview`} />
                </Button>
            </PopupFooter>
        </Popup>
    );
};
