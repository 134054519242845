import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";
import { ScrollableArea } from "../../layout/ScrollableArea";

export const PopupContent = ({ padding, border, noShrink, fill, children }) => {
    return (
        <div
            className={classNames(styles.content, {
                [styles.paddingNone]: padding === "none",
                [styles.paddingMedium]: padding === "medium",
                [styles.borderTop]: border === "top",
                [styles.borderBottom]: border === "bottom",
                [styles.noShrink]: noShrink,
                [styles.fill]: fill
            })}
        >
            {/* ScrollableArea is added here in favor of native scroll due to a bug in mobile Safari 17.5 where scrolling does not work inside flex-box:
                 https://bugs.webkit.org/show_bug.cgi?id=275209. When this gets fixed, we can revert back to native scroll. */}
            <ScrollableArea>
                <div className={styles.inner}>{children}</div>
            </ScrollableArea>
        </div>
    );
};
