import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";

import { Icon } from "../../graphic/Icon";

export const Checkbox = ({ onToggle, checked, noMargin, disabled, children, className }) => {
    const handleToggle = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onToggle && onToggle(checked);
    };

    return (
        <div
            className={classNames(
                styles.base,
                {
                    [styles.isDisabled]: disabled,
                    [styles.isChecked]: checked,
                    [styles.noMargin]: noMargin
                },
                className
            )}
            onClick={!disabled ? handleToggle : null}
        >
            <div className={styles.check}>
                <Icon name="check" className={styles.icon} />
            </div>
            {children ? <div className={styles.label}>{children}</div> : null}
        </div>
    );
};
