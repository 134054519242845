import React from "react";
import styles from "./Breadcrumbs.scss";

import classNames from "classnames";
import { Clickable } from "../../button/Clickable";
import { Icon } from "../../graphic/Icon";

export const Crumb = ({ link, onClick, label, code, active, separator }) => {
    return (
        <div
            className={classNames(styles.crumb, {
                [styles.clickable]: !!onClick || !!link,
                [styles.active]: active
            })}
        >
            <Clickable
                className={styles.item}
                link={link}
                onClick={onClick}
                title={`${code ? code + ". " : ""}${label}`}
            >
                {!!code && <div className={styles.code}>{code}.</div>}
                <div className={styles.label}>{label}</div>
                <div className={styles.dots}>
                    <Icon name="dots-hor" />
                </div>
            </Clickable>
            <div className={styles.separator}>
                {separator === "slash" ? (
                    <span className={styles.slash}>/</span>
                ) : (
                    <Icon className={styles.icon} name="chevron-right" />
                )}
            </div>
        </div>
    );
};
