import classNames from "classnames";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";
import { Clickable } from "../../../button/Clickable";
import { Icon } from "../../../graphic/Icon";
import { MeMenu } from "../MeMenu";
import { QuickSelector } from "../QuickSelector";
import style from "./style.scss";

export const BarPane = ({ onlyPhone, options }) => {
    const navigate = useNavigate();
    const { setShowSidebar } = useContext(AppContext);
    const { left, center } = options || {};
    const [showUserBar, setShowUserBar] = useState(false);
    const [showMeMenu, setShowMeMenu] = useState(false);

    const handleClickItem = (item) => {
        setShowUserBar(false);
        setShowMeMenu(false);

        // Toggle sidebar
        if (item.toggleSidebar) {
            setShowSidebar(true);
        }

        // Link
        else if (item.link) {
            navigate(item.link);
        }

        // Handler
        else if (item.handler) {
            item.handler();
        }
    };

    const handleClickRight = () => {
        setShowUserBar(!showUserBar);
        setShowMeMenu(showUserBar && showMeMenu ? false : showMeMenu);
    };

    return (
        <div
            className={classNames(style.base, {
                [style.onlyPhone]: onlyPhone
            })}
        >
            {/* USER BAR */}
            <div
                className={classNames(style.userBar, {
                    [style.visible]: showUserBar
                })}
            >
                <div className={style.left}>
                    <QuickSelector />
                </div>
                <div className={style.right}>
                    {/* Icon menu */}
                    <div className={style.iconMenu}>
                        <Clickable className={style.item} link={`/me/help`}>
                            <div className={style.circle}>
                                <Icon name="question" />
                            </div>
                        </Clickable>
                    </div>

                    {/* Me */}
                    <div className={style.meMenu}>
                        <MeMenu />
                    </div>
                </div>
            </div>

            {/* MAIN BAR */}
            <div className={style.mainBar}>
                {/* Left */}
                {left ? (
                    <Clickable onClick={() => handleClickItem(left)} className={style.left}>
                        {left.icon && !left.toggleSidebar ? (
                            <div className={style.iconButton}>
                                <Icon name={left.icon} className={style.icon} />
                            </div>
                        ) : null}

                        {left.icon && left.toggleSidebar ? (
                            <div className={style.menuButton}>
                                <Icon size="large" name={left.icon} className={style.icon} />
                            </div>
                        ) : null}
                    </Clickable>
                ) : null}

                {/* Center */}
                {center ? (
                    <div className={style.center}>
                        {center.progress ? (
                            <div className={style.progress}>
                                <div className={style.bar}>
                                    <div style={{ width: `${center.progress}%` }} className={style.trail} />
                                </div>
                            </div>
                        ) : (
                            <Clickable onClick={() => handleClickItem(center)} className={style.text}>
                                {center.label}
                            </Clickable>
                        )}
                    </div>
                ) : null}

                {/* Right */}
                <Clickable onClick={handleClickRight} className={style.right}>
                    <div
                        className={classNames(style.iconButton, {
                            [style.active]: showUserBar
                        })}
                    >
                        <Icon size="medium" name="dots-triangle" className={style.icon} />
                    </div>
                </Clickable>
            </div>
        </div>
    );
};
