import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { deleteMeCrew } from "../../../api/crew/crew";
import { AuthContext } from "../../../contexts/Auth";
import { VesselContext } from "../../../routes/vessel/VesselRoot";
import { Box, BoxContent } from "../../box/Box";
import { Button } from "../../button/Button";
import { ConfirmActionButton } from "../../button/ConfirmActionButton";
import { ShieldLogo } from "../../graphic/ShieldLogo/ShieldLogo";
import Col from "../../grid/Col";
import Row from "../../grid/Row";
import Message from "../../notify/Message";
import { PageMessage } from "../../page/PageMessage";
import { Text } from "../../typography/Text";

const msgKey = "buyVesselSubscriptionMessage";

export const BuyVesselSubscriptionMessage = ({ vessel, vesselRole }) => {
    const navigate = useNavigate();
    const { reloadMeContext } = useContext(AuthContext);
    const { crewRole } = useContext(VesselContext);
    const [busyRemoving, setBusyRemoving] = useState(false);

    const handleRemoveSelf = () => {
        setBusyRemoving(true);
        deleteMeCrew(vessel.id).then(() => {
            reloadMeContext();
            Message.success(`${msgKey}.selfRemoveSuccess`);
            navigate(`/me`, { replace: true });
        });
    };

    return vesselRole === "OWNER" ? (
        <PageMessage
            title={<FormattedMessage id={`${msgKey}.buySubscriptionTitle`} />}
            figure={<ShieldLogo size={100} />}
        >
            <Row gutter={["large", "large"]}>
                <Col span={24}>
                    <Text block size="medium" color="base80">
                        <FormattedMessage
                            id={`${msgKey}.buySubscriptionMessage`}
                            values={{ vesselName: vessel.name, strong: (msg) => <strong>{msg}</strong> }}
                        />
                    </Text>
                </Col>
                <Col sm={{ span: 14, offset: 5 }}>
                    <Button
                        fill
                        link={`/vessels/${vessel.id}/subscription/choose`}
                        color="success"
                        icon="account"
                        size="big"
                    >
                        <FormattedMessage id={`${msgKey}.chooseSubscription`} />
                    </Button>
                </Col>
            </Row>
        </PageMessage>
    ) : (
        <Row>
            <Col span={24}>
                <PageMessage
                    title={<FormattedMessage id={`${msgKey}.missingSubscriptionTitle`} />}
                    figure={<ShieldLogo size={100} />}
                >
                    <Text block size="medium" color="base80">
                        <FormattedMessage
                            id={`${msgKey}.missingSubscriptionMessage`}
                            values={{ vesselName: vessel.name, strong: (msg) => <strong>{msg}</strong> }}
                        />
                    </Text>
                </PageMessage>
            </Col>
            {!!crewRole && (
                <Col span={24}>
                    <Box>
                        <BoxContent>
                            <ConfirmActionButton
                                popup
                                busy={busyRemoving}
                                label={<FormattedMessage id={`${msgKey}.removeMeFromVessel`} />}
                                question={<FormattedMessage id={`${msgKey}.removeMeFromVesselConfirm`} />}
                                onConfirm={handleRemoveSelf}
                            />
                        </BoxContent>
                    </Box>
                </Col>
            )}
        </Row>
    );
};
